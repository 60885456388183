<template>
  <div class="page-login-desktop">

    <Table
        label="لیست کاربران انلاین"
        icon="mdi-format-list-bulleted"
        :table="table">
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.agencyname }}</td>
        <td class="text-center">{{ item.nameandfamily }}</td>
        <td class="text-center">{{ item.mobile }}</td>
        <td class="text-center">{{ item.dateofRegistration }}</td>
        <td class="text-center">{{ item.lastVisit }}</td>
        <td class="text-center">{{ item.userIp }}</td>
        <td class="text-center">{{ item.operatingsystemname }}</td>
        <td class="text-center">{{ item.browsertype }}</td>
        <td class="text-center">{{ item.devicename || '----' }}</td>
        <td>
          <EditButton
              v-if="item.accountType !== -1"
              @click="item.link"
          />
        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <NewMessage
        @onSubmit="()=>{
          newMessage.modal=false;
        }"
        :messageData="newMessage"
    />

  </div>
</template>

<script>
import Table from "@/components/Table"
import EditButton from "@/components/EditButton"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {adminOnlineUsers} from "@Newfiling/module-user/src/api";
import moment from "jalali-moment";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
    EditButton
  },

  data() {
    return {
      filters: {
        modal: false,
        Nameandfamily: null,
        skip: 0,
        take: 10,
        accountType: 0
      },

      table: {
        headers: [
          '#',
          'نام آژانس',
          'نام کاربر',
          'موبایل',
          'تاریخ ثبت نام',
          'اخرین بازدید',
          'IP',
          'سیستم عامل',
          'نوع مرورگر',
          'نام دستگاه',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      viewMessage: {
        modal: false,
        body: "",
      },

      newMessage: {
        modal: false,
        phoneNumber: null,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست کاربران',
        disabled: false,
        href: '/panel/users/list',
      },
      {
        text: 'لیست کاربران انلاین',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.filters.modal = false;
      this.table.loading = true;
      try {
        const res = (await adminOnlineUsers({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          list: [],
          recordcount: 0
        };


        this.table.items = res?.list.map(item => {
          return {
            ...item,
            lastVisit: moment(item.lastvisit).format('jYYYY-jMM-jDD HH:mm:ss'),
            link: () => {
              const link = item.accountType >= 3
                  ? '/panel/admins/edit/' + item.userid
                  : '/panel/users/edit/' + item.userid
              this.$router.push(link);
            },
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },
  }

}
</script>


